export default {
  "login": {
    "titolo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to watch the live stream"])},
    "lostPsw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I forgot my credentials"])}
  },
  "credenzialiSmarrite": {
    "testoIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the registration email and we will resend the credentials."])},
    "pulsanteConfermaMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Email"])},
    "alertErroreTitolo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
    "alertErroreTesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email entered is not registered in our system for this event"])},
    "alertSuccessoTitolo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid Email!"])},
    "alertSuccessoTesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sending the access credentials to your email."])}
  },
  "authAlerts": {
    "attenzioneAlertTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
    "concurrentConnessionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection cannot be made from two devices simultaneously. Please disconnect one and try again."])},
    "credenzialiNonValide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The provided credentials are incorrect or the user is not registered for the service. Please check the email sent with the credentials or register for the service."])}
  },
  "registrazione": {
    "titolo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register for the Service"])},
    "punto1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Enter and validate your email:"])},
    "punto2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the "])},
    "punto21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms of use"])},
    "punto3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Complete the registration by making the payment:"])},
    "modificaMailButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Email"])},
    "confermaMailButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Email"])}
  },
  "registrazioneAlerts": {
    "successoTitolo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations!"])},
    "attenzioneTitolo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
    "successoTesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration was successful. You will soon receive access credentials to the email used during registration."])},
    "mailNonValidaTesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entered email is not valid or already associated with another user."])}
  },
  "descSpecial": {
    "introduzione1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To register for the streaming service, follow the steps outlined in the 'Register for the Service' section."])},
    "introduzione2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In particular, you will be asked to:"])},
    "elencoPunto1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid email to which you will later receive your password"])},
    "elencoPunto2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept the site's terms of use"])},
    "elencoPunto3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete registration by payment. The cost of the live stream is €"])},
    "conclusione1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not receive or lose the email containing the access credentials, it can be requested again by clicking on the 'I forgot my credentials' button in the login section."])},
    "conclusione2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For further issues, you can contact us at the email address"])},
    "conclusione3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you and enjoy the show."])}
  },
  "canaliLive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live this week"])},
  "canaliOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our other channels"])},
  "sponsor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks to our sponsors!"])},
  "chiudiFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
}